@import './layout/assets/breakPoints'; // breakPoints

header {
  height: 95vh;
  background: linear-gradient(to right bottom, #0bb8a6d3, #11dfcae7),
    url(../assets/img/hero.jpg) no-repeat top center;
  background-size: cover;
  clip-path: polygon(0 0, 100% 0, 100% 75vh, 0% 100%);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  // ====== Reponsive ================
  @media (max-width: 578px) {
    clip-path: polygon(0 0, 100% 0, 100% 87vh, 0% 100%);
  }

  // Logo
  .logo {
    position: absolute;
    top: 4rem;
    left: 4rem;

    img {
      width: 10rem;
      transition: 0.2s;
    }

    :hover {
      transform: rotate(-10deg);
    }
  }

  // Title
  .title {
    color: white;
    text-transform: uppercase;
    margin-top: -10rem;

    h1 {
      font: bold 8vw sans-serif;
      letter-spacing: 2.5rem;
      margin-bottom: 2rem;
      text-align: center;
    }

    p {
      text-align: center;
      font-size: calc(0.7rem + 2.6vw);
      font-family: monospace;
      letter-spacing: 0.5rem;
      margin-bottom: 5rem;
    }
  }

  // Btn
  a {
    all: unset;
    background-color: #fff;
    box-shadow: 0.4rem 0.4rem #fff;
    color: #777777;
    padding: 1.5rem 3rem;
    cursor: pointer;
    font: bold 1.5rem 'lemonada', cursive;
    text-transform: capitalize;
    transition: 0.1s;
    position: relative;

    //Hover
    &:hover {
      background-color: #00ffdd;
      color: #000;
      box-shadow: -4px -4px #fff;
    }

    //Active
    &:active {
      top: 0.5rem;
    }
  }
}
