.stories {
  padding: 15rem 0;
  position: relative;
  overflow: hidden;

  // Video
  .bg-vid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    opacity: 0.2;
  }

  // Title
  .title {
    text-align: center;
    margin-bottom: 8rem;

    h2 {
      font: 700 3.5rem 'Lemonada', cursive;
      text-align: center;
      text-transform: uppercase;
      display: inline-block;
      background-image: linear-gradient(to right, #32ccbde7, #90f7edd3);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      transition: 0.2s;

      // Hover
      &:hover {
        transform: skew(5deg, 5deg) scale(1.1);
        text-shadow: 2px 2px 1px #32ccbde7;
      }
    }
  }

  .story {
    width: 80%;
    padding: 7rem 8rem;
    margin: 0 auto;
    border-radius: 2px;
    box-shadow: 2px 2px 20px #00000011;
    background-color: #f7f7f7da;
    clip-path: polygon(8% 0, 100% 0%, 92% 100%, 0% 100%);
    margin-bottom: 5rem;
    font: 700 1rem 'Lemonada', cursive;
    text-transform: uppercase;

    // Shape
    .shape {
      width: 15rem;
      height: 15rem;
      border-radius: 50%;
      float: left;
      shape-outside: circle(47% at 41% 47%);
      clip-path: circle(52% at 58% 56%);
      transform: translate(-3rem, -1.5rem);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    // Shape 1
    .shape1 {
      background-image: url('../assets/img/nat-8.jpg');
      background-size: cover;
    }

    // Shape 2
    .shape2 {
      background-image: url('../assets/img/nat-9.jpg');
      background-size: cover;
    }
  }
}
