.features {
  padding: 20rem 5rem;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
  clip-path: polygon(0 21%, 100% 1%, 100% 83%, 0% 95%);
  margin-top: -30vh;

  // sm 576px
  @include rwd(xs) {
    clip-path: polygon(0 8%, 100% 1%, 100% 94%, 0% 95%);
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 3rem;

    // xm 350px
    @include rwd(xs) {
      grid-template-columns: 1fr;
    }

    // sm 576px
    @include rwd(md) {
      grid-template-columns: repeat(2, 1fr);
    }

    // sm 576px
    @include rwd(lg) {
      grid-template-columns: repeat(4, 1fr);
    }

    .feature-box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      transition: 0.2s;
      padding: 1rem;
      background-color: #ffffff71;
      height: 400px;
      border-radius: 35px;

      // xm 350px
      @include rwd(xs) {
        margin-bottom: 2rem;
      }

      &:hover {
        transform: scale(1.1) rotate(-5deg);
        cursor: pointer;
      }

      i {
        font-size: 8rem;
        background-image: linear-gradient(to right, orange, gold);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
      }

      h3 {
        font: bold 1.5rem 'Lemonada', cursive;
        margin-bottom: 1rem;
        text-transform: uppercase;
      }

      p {
        font: normal 1.2rem/25px 'Lemonada', cursive;
        letter-spacing: 0.2px;
        text-align: center;
      }
    }
  }
}
