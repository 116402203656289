.about {
  background-color: #f7f7f7;
  padding: 25rem 0;
  margin-top: -20vh;

  // Title
  .title {
    text-align: center;
    margin-bottom: 8rem;

    h2 {
      font: 700 3.5rem 'Lemonada', cursive;
      text-align: center;
      text-transform: uppercase;
      display: inline-block;
      background-image: linear-gradient(to right, #90f7edd3, #32ccbde7);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      transition: 0.2s;

      // Hover
      &:hover {
        transform: skew(5deg, 5deg) scale(1.1);
        text-shadow: 2px 2px 1px #32ccbde7;
      }
    }
  }

  // Info
  .info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 20px;

    // lg 992px
    @media (max-width: 992px) {
      grid-template-columns: 1fr;
    }

    // Text
    .text {
      h1 {
        font: 700 1.7rem 'Lemonada', cursive;
        letter-spacing: 0.2px;
        margin-bottom: 15px;
      }

      p {
        font: normal 1.2rem/25px 'Lemonada', cursive;
        letter-spacing: 0.2px;
        width: 465px;
        margin-bottom: 20px;
      }

      a {
        all: unset;
        font: 700 1.7rem 'Lemonada', cursive;
        color: #32ccbde7;
        border-bottom: 3px solid #90f7edd3;
        cursor: pointer;
        padding: 10px;
        display: inline-block;
        transition: 0.1s;

        // Hover
        &:hover {
          background-color: #90f7edd3;
          box-shadow: 0 1rem 2rem #0000001e;
          transform: translateY(0px);
          transform: scale(1.1);
        }

        // Active
        &:active {
          box-shadow: 0 0.5rem 1rem #0000001e;
          transform: translateY(0);
        }
      }
    }

    // Images
    .composition {
      position: relative;
      margin: 7.5rem 5rem 37rem 0;

      img {
        width: 55%;
        box-shadow: 0 1.5rem 4rem #00000041;
        border-radius: 2rem;
        position: absolute;
        transition: all 0.2s;
        outline-offset: 1rem;

        // Hover
        &:hover {
          outline: 1.5rem solid #90f7edd3;
          box-shadow: 0 2.5rem 4rem #00000073;
          transform: scale(1.05) rotate(-3deg);
          z-index: 10;
        }
      }

      // Hover other images
      &:hover img:not(:hover) {
        transform: scale(0.9);
      }

      .img1 {
        left: 0;
        top: -2rem;
      }

      .img2 {
        right: 0;
        top: 2rem;
      }

      .img3 {
        left: 20%;
        top: 10rem;
      }
    }
  }
}
