.popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  backdrop-filter: blur(10px);
  background-color: #2121216e;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transition: 0.1s;

  &:target {
    opacity: 1;
    visibility: visible;
  }

  // Content
  .popup-content {
    background-color: #fff;
    height: 60%;
    width: 90%;
    border-radius: 5px;
    position: relative;
    display: flex;

    // Close Btn
    .close-btn {
      position: absolute;
      right: 19px;
      top: 8px;
      font-size: 7rem;
      text-decoration: none;
      color: #222;
    }

    // Imgs
    .img-box {
      img {
        width: 100%;
        height: 50%;
        display: block;
      }
    }

    // Text
    .details {
      padding: 3rem;
      align-self: center;

      h2 {
        font: 700 3.5rem 'Lemonada', cursive;
        text-align: center;
        text-transform: uppercase;
        display: inline-block;
        background-image: linear-gradient(to right, #90f7edd3, #32ccbde7);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        transition: 0.2s;
        margin-bottom: 1rem;

        // Hover
        &:hover {
          transform: skew(5deg, 5deg) scale(1.1);
          text-shadow: 2px 2px 1px #32ccbde7;
        }
      }

      h3 {
        text-transform: uppercase;
        font: 1.5rem 'lemonada', cursive;
        letter-spacing: 0.4px;
        margin-bottom: 1rem;
      }

      .paragraph {
        display: flex;

        p {
          font: 1rem/2rem 'lemonada', cursive;
          letter-spacing: 0.2px;
          display: inline-block;
        }

        :nth-child(1) {
          margin-right: 2rem;
          border-right: 1.5px solid #eee;
          padding-right: 1rem;
        }
      }

      a {
        all: unset;
        font: 700 1.3rem 'Lemonada', cursive;
        color: #32ccbde7;
        border-bottom: 3px solid #90f7edd3;
        cursor: pointer;
        padding: 10px;
        display: inline-block;
        transition: 0.1s;
        transform: translateY(2.5rem);

        // Hover
        &:hover {
          background-color: #90f7edd3;
          box-shadow: 0 1rem 2rem #0000001e;
        }

        // Active
        &:active {
          box-shadow: 0 0.5rem 1rem #0000001e;
        }
      }
    }
  }
}
