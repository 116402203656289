.navigation {
  // Input
  input {
    display: none;

    &:checked ~ nav {
      display: block;
    }
  }

  // label
  label {
    height: 7rem;
    width: 7rem;
    position: fixed;
    top: 6rem;
    right: 6rem;
    z-index: 2000;
    cursor: pointer;
  }

  // BackGround
  .bg {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    position: fixed;
    top: 5rem;
    right: 5rem;
    background-color: #fff;
    border: 2px solid #000;
    z-index: 1000;

    img {
      position: absolute;
      top: 12px;
      left: 12px;
    }

    @media (max-width: 576px) {
      top: 4rem;
      right: 3rem;
    }
  }

  // Nav
  nav {
    background-image: linear-gradient(to right bottom, #14e68e, #40a399);
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;

    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 10rem;

      li {
        all: unset;
        margin: 2rem;

        a {
          all: unset;
          cursor: pointer;
          font: 2.5em 'lemonada', cursive;
          color: #fff;
          padding: 1rem 2rem;
          background-size: 220%;
          background-image: linear-gradient(
            113deg,
            transparent 0%,
            transparent 50%,
            #fff 50%
          );
          transition: 0.2s;
          display: inline-block;

          // Hover
          &:hover {
            background-position: 100%;
            color: #111;
            transform: translateX(1rem);
          }
        }
      }
    }
  }
}
